$(document).ready(function() {

// FORM INPUT MANIPULATION

    new InputValueManipulation().initialize();

// POPULATE FORM-ITEMS

    var addFormAttributes = new AddFormAttributes();

    addFormAttributes.disableAutoCorrect($('input'));
    addFormAttributes.addNumberInputOnMobile($(
        '.form-item.electricity input, ' +
        '.form-item.electricity-business input, ' +
        '.form-item.reading-electricity input, ' +
        '.form-item.gas input, ' +
        '.form-item.gas-business input, ' +
        '.form-item.reading-gas input, ' +
        '.form-item.numbers input, ' +
        '.form-item.house-number input, ' +
        '.form-item.kvk input, ' +
        '.form-item.monthly-amount input, ' +
        '.form-item.not-zero input, ' +
        '.form-item.phone input,' +
        '.form-item.amount input,' +
        '.form-item.phone-all-in-one input,' +
        '.form-item.phone-all-in-one-no-mobile input,' +
        '.form-item.icc-code input'
    ));

    // functions for adding a prefix to certain input fields.
    new AddInputPrefix().initialize();

    // function for adding a select overlay to select form-items.
    addSelectOverlay($('.form-item.select'));

    // function for adding a date select overlay to date form items.
    dateSelectBehaviour();

    // function for adding a file upload overlay to file form-items.
    new FileUpload().initialize();

    // function for showing and hiding a placeholder in placeholder form items.
    new PlaceholderFormItems().initialize();

    new CheckboxAndRadioButtonFunctionality().initialize();

    // Add date picker and functionality to the date picker form item.
    datePickerBehaviour();

    new InitialsOptimiser().initialize();

    new IbanOptimiser().initialize();

    new PreventInput().initialize();

    // Add a password indicator to the password form item when the user inputs a value.
    indicatePasswordStrength();

    new ReadOnly().initialize();

// FAKE TAB INDEX

    fakeTabIndex();

// STEP INDICATOR

    new StepsIndicator().initialize();

// VALIDATIONS

    // Set the form reset validation
    resetValidation();

    // Set al the default form validations
    defaultLiveValidation();

});

// ADD FORM ATTRIBUTES

function AddFormAttributes() {
    /**
     * @param {object} formElements jquery collection of form inputs
     */
    this.disableAutoCorrect = function (formElements) {
        formElements.attr({
            autocorrect: 'off',
            autocapitalize: 'off',
        });
        formElements.not('[autocomplete]').attr('autocomplete', 'off');
    };



    this.addNumberInputOnMobile = function (formElement) {
        var detectMobile = new MobileDeviceRecognition();
        if (detectMobile.hasIOS() || detectMobile.hasAndroid()) {
            formElement.attr({
                type: 'number',
                pattern: '[0-9]*',
            });
        }
    };
}

// POPULATE FORM-ITEMS

    function AddInputPrefix() {

        var scope = this;

        this.initialize = function() {

            scope.addUnitPrefix('electricity', 'kWh');
            scope.addUnitPrefix('gas', 'm&sup3;');
            scope.addUnitPrefix('electricity-business', 'kWh');
            scope.addUnitPrefix('gas-business', 'm&sup3;');
            scope.addUnitPrefix('reading-electricity', 'kWh');
            scope.addUnitPrefix('reading-gas', 'm&sup3;');
            scope.addUnitPrefix('amount', '€');

            addRequiredPrefix();

        }

        this.addUnitPrefix = function(formItemClass, unit) {
            $(
                '.form-item.' + formItemClass + ' input[type=text],' +
                ' .form-item.' + formItemClass + ' input[type=number]'
            ).wrap('<div class="unit-wrapper"></div>').after('<span class="unit">' + unit + '</span>');
        }

        var addRequiredPrefix = function() {
            var required = $('.form-item.required');
            required.each(function() {
                $(this).children('label:first-child').append('<span> *</span>');
            });
        }

    }


// ADD FILE UPLOAD OVERLAY

    function FileUpload() {

        var scope = this;

        this.initialize = function() {

            $('.form-item.file.document').each(function() {
                createFileUpload($(this));
            });

            $('.form-item.file.image').each(function() {
                createImageUpload($(this));
            });

        };

        var createFileUpload = function(formItem) {

            var buttonText = Translator.trans('formOverlay.fileOverlay.file.button.notSelected');
            var unSelectedFileText = Translator.trans('formOverlay.fileOverlay.file.preview.notSelected');

            addInputOverlay(formItem, buttonText);
            addUploadStatusText(formItem, unSelectedFileText);

            formItem.find('.add-file').click(function () {

                formItem.find('input').click();

            });

            formItem.find('input').on('change', function() {

                addUploadStatusText(formItem, unSelectedFileText);

            });

            formItem.find('.preview-file-delete').click(function() {

                scope.clearFileInput(formItem, unSelectedFileText);

            });

        };

        var createImageUpload = function(formItem) {

            var buttonText = Translator.trans('formOverlay.fileOverlay.image.button.notSelected');
            var unSelectedFileText = Translator.trans('formOverlay.fileOverlay.image.preview.notSelected');

            addInputOverlay(formItem, buttonText);
            addUploadStatusText(formItem, unSelectedFileText);

            formItem.find('.add-file').click(function () {

                formItem.find('input').click();

            });

            formItem.find('input').on('change', function() {

                addUploadStatusText(formItem, unSelectedFileText);
                addPreviewImage(formItem, this);

            });

            formItem.find('.preview-file-delete').click(function() {

                scope.clearFileInput(formItem, unSelectedFileText);

            });

        };

        var addInputOverlay = function(formItem, buttonText) {

            formItem.find('input').after(
                '<div class="file-overlay">' +
                    '<div class="add-file">' +
                        '<span class="add-file-text">' +
                            buttonText +
                        '</span>' +
                    '</div>' +
                    '<div class="preview-file">' +
                        '<span class="preview-file-text"></span>' +
                        '<span class="preview-file-delete"></span>' +
                    '</div>' +
                '</div>'
            );

        };

        var addUploadStatusText = function(formItem, unSelectedText) {

            var isFileSelected = formItem.find('input').val() != '';

            if (isFileSelected) {

                var selectedText = formItem.find('input').val().split('\\').pop();

                formItem.find('.preview-file-text').text(selectedText);
                formItem.addClass('selected');

            } else {

                formItem.find('.preview-file-text').text(unSelectedText);
                formItem.removeClass('selected');

            }
        };

        var addPreviewImage = function(formItem, scope) {

            var isFileSelected = scope.files[0];

            formItem.find('.preview-file img').remove();
            formItem.find('.preview-file .document-thumbnail').remove();
            formItem.removeClass('image-added');
            formItem.removeClass('document-added');

            if (isFileSelected) {

                var fileType = formItem.find('input').val().split('.').pop();

                if (fileType == 'pdf') {

                    formItem.addClass('document-added');

                    return;

                }

                var fileReader = new FileReader();

                fileReader.onload = function(event) {

                    var imageUrl = event.target.result;

                    formItem.find('.preview-file').prepend(
                        '<img src="' + imageUrl + '" class="preview-file-hover" />' +
                        '<img src="' + imageUrl + '" class="preview-file-thumbnail" />'
                    );

                    formItem.addClass('image-added');

                };

                fileReader.readAsDataURL(isFileSelected);

            }

        };

        this.clearFileInput = function(formItem, unSelectedFileText) {

            formItem.wrap('<form>').closest('form').get(0).reset();
            formItem.unwrap();
            formItem.find('input').trigger('change');
            addUploadStatusText(formItem, unSelectedFileText);

        };

    }


// ADD SELECT OVERLAY

    // function for adding a select dropdown overlay to select form-items.
    function addSelectOverlay(selectFormItem) {
        selectFormItem.each(function() {
            populateSelectOverlay($(this));
            selectOverlayBehaviour($(this));
        });
    }

    // POPULATE SELECT FORM ITEM

        // Populate the select form item with the select overlay.
        function populateSelectOverlay(select) {
            populateSelectOverlayWrappers(select);
            populateSelectOptions(select);
            populateSelectToggle(select);
        }

        // Populate the select form item with the wrappers.
        function populateSelectOverlayWrappers(selectFormItem) {
            selectFormItem.find('select').after('<div class="select-overlay"><div class="overlay-toggle"><span class="text"></span><span class="toggle"></span></div><div class="options"></div></div>');
        }

        // Populate the select overlay toggle with the by default selected option.
        function populateSelectToggle(selectFormItem) {
            var selectedOption = selectFormItem.find('option:selected');
            var selectedOptionExists = selectedOption.is('[selected]');
            if (selectedOptionExists) {
                selectFormItem.find('.overlay-toggle .text').text(selectedOption.text());
                setTouchedSelect(selectFormItem);
                selectFormItem.find('.option').each(function() {
                    if ($(this).text() == selectedOption.text()) {
                        $(this).addClass('selected');
                    }
                });
                return;
            }
            populateSelectAddPlaceholder(selectFormItem);
        }

        // Add the correct placeholder text when no option is selected.
        function populateSelectAddPlaceholder(selectFormItem) {
            var nonSelectedText = Translator.trans('formOverlay.selectOverlay.default');
            selectFormItem.each(function() {
                var isPlaceholder = $(this).hasClass('placeholder');
                if (isPlaceholder) {
                    var labelText = $(this).find('label').html();
                    $(this).find('.overlay-toggle .text').html(labelText);
                } else {
                    $(this).find('.overlay-toggle .text').text(nonSelectedText);
                }
                $(this).removeClass('touched invalid');
            });
        }

        // Populate the select overlay option block with all the options.
        function populateSelectOptions(selectFormItem) {
            var optionsArray = [];
            var sortAttrExists = selectFormItem.is("[option-sort]");
            var sortAscending = selectFormItem.attr('option-sort') == 'ascending';
            var sortDescending = selectFormItem.attr('option-sort') == 'descending';
            var noSort = selectFormItem.attr('option-sort') == 'none';
            selectFormItem.find('select option').each(function() {
                var optionValue = $(this).text();
                optionsArray.push(optionValue);
            });
            if (!sortAttrExists || sortAscending) {
                selectFormItem.find('.options').append('<div class="option">' + optionsArray.sort().join('</div><div class="option">') + '</div>');
            } else if (sortDescending) {
                selectFormItem.find('.options').append('<div class="option">' + optionsArray.sort().reverse().join('</div><div class="option">') + '</div>');
            } else if (noSort) {
                selectFormItem.find('.options').append('<div class="option">' + optionsArray.join('</div><div class="option">') + '</div>');
            }
        }

    // ADD BEHAVIOUR TO SELECT OVERLAY

        // Add functionality to the select overlay.
        function selectOverlayBehaviour(selectFormItem) {
            toggleSelectOverlay(selectFormItem);
            selectFormItem.find('.option').click(function() {
                selectOptionSelectOverlay($(this));
            });
            clickAwaySelectOverlay(selectFormItem);
            keyboardSelectBehaviour(selectFormItem);
        }

        // Toggle the select overlay.
        function toggleSelectOverlay(selectFormItem) {
            selectFormItem.find('.overlay-toggle').click(function() {
                var scope = $(this).closest('.form-item');
                if (scope.find('select').is(':disabled')) {
                    return;
                }
                var isOpen = scope.hasClass('open');
                var options = scope.find('.options');
                if (isOpen) {
                    scope.removeClass('open').addClass('closed');
                    options.slideUp(200);
                } else {
                    var selectedOption = scope.find('.option.selected');
                    scope.removeClass('open').addClass('closed');
                    scope.find('.options').slideUp(200);
                    scope.addClass('open').removeClass('closed');
                    options.slideDown(200);
                }
            });
        }

        // Select an option in the select overlay.
        function selectOptionSelectOverlay(option) {
            var scope = option.closest('.form-item');
            var optionText = option.text();
            var realOptionValue = scope.find('option').filter(function() {
                return $(this).text() === optionText;
            }).attr('value');
            scope.find('.option').removeClass('selected');
            option.addClass('selected');
            scope.find('.overlay-toggle .text').text(optionText);
            scope.find('.overlay-toggle').click();
            changeOptionSelect(scope, realOptionValue);
            setTouchedSelect(scope);
        }

        // Changes the value in the original hidden select element.
        function changeOptionSelect(scope, optionText) {
            scope.find('select').val(optionText).change();
        }

        // When clicked outside of the select overlay, close the options.
        function clickAwaySelectOverlay(overlay) {
            $(document).click(function(event) {
                if (!$(event.target).closest(overlay).length && overlay.closest('.form-item.select').hasClass('open')) {
                    overlay.closest('.form-item.select').removeClass('open').addClass('closed');
                    overlay.find('.options').slideUp(200);
                }
            });
        }

        // Add the class "touched" to the select form item.
        function setTouchedSelect(formItem) {
            if (!formItem.hasClass('touched')) {
                formItem.addClass('touched');
            }
        }

        // Add keyboard input functionality when the select drop down is open.
        function keyboardSelectBehaviour(selectFormItem) {
            $(document).keydown(function(e) {
                var code = e.keyCode || e.which;

                // If the select drop down option list is opened up/visible, add new keyboard behaviour.
                var openSelect = selectFormItem.filter('.select.open');
                var selectIsOpen = openSelect.length;
                if (selectIsOpen) {

                    // Prevent the default key behaviour as we're about to overwrite it.
                    e.preventDefault();

                    // If the up key is pressed.
                    if (code == '38') {
                        upKeySelectBehaviour(openSelect);
                    }

                    // If the down key is pressed.
                    if (code == '40') {
                        downKeySelectBehaviour(openSelect);
                    }

                    // If the enter/return key is pressed.
                    if (code == '13') {
                        enterKeySelectBehaviour(openSelect);
                    }
                }
            });
        }

        // Up key behaviour for select drop down.
        // openSelect: This parameter is the openSelect form item jQuery object.
        function upKeySelectBehaviour(openSelect) {
            var selectedOption = openSelect.find('.option.selected');
            var optionIsSelected = selectedOption.length;
            var selectedOptionIsFirst = selectedOption.is(':first-child');

            // When no option is selected, select the last option on the list.
            if (!optionIsSelected) {
                selectOptionInSelect(openSelect.find('.option:last-child'));
                return;
            }

            // If there is no other option on top other then the current selected option, don't do anything.
            if (selectedOptionIsFirst) {
                return;
            }

            // Select the previous option.
            var previousOption = selectedOption.prev('.option');
            selectOptionInSelect(previousOption);
        }

        // Down key behaviour for select drop down.
        // openSelect: This parameter is the openSelect form item jQuery object.
        function downKeySelectBehaviour(openSelect) {
            var selectedOption = openSelect.find('.option.selected');
            var optionIsSelected = selectedOption.length;
            var selectedOptionIsLast = selectedOption.is(':last-child');

            // When no option is selected, select the first option on the list.
            if (!optionIsSelected) {
                selectOptionInSelect(openSelect.find('.option:first-child'));
                return;
            }

            // If there is no other option on top other then the current selected option, don't do anything.
            if (selectedOptionIsLast) {
                return;
            }

            // Select the next option.
            var nextOption = selectedOption.next('.option');
            selectOptionInSelect(nextOption);
        }

        // Select the prefered option on the list.
        function selectOptionInSelect(option) {
            var openSelect = option.closest('.form-item');
            var optionText = option.text();
            var realOptionValue = openSelect.find('option').filter(function() {
                return $(this).text() === optionText;
            }).attr('value');
            openSelect.find('.option').removeClass('selected');
            option.addClass('selected');
            openSelect.find('.overlay-toggle .text').text(optionText);
            changeOptionSelect(openSelect, realOptionValue);
            setTouchedSelect(openSelect);
            setScrollPositionSelectOverlay(openSelect, option);
        }

        // Return/enter key behaviour for select drop down.
        // openSelect: This parameter is the openSelect form item jQuery object.
        function enterKeySelectBehaviour(openSelect) {
            var selectedOption = openSelect.find('.option.selected');
            var optionIsSelected = selectedOption.length;
            var dateFormItem = openSelect.closest('.form-item.date');
            var isDateFormItem = dateFormItem.length;

            // If no option is selected, close the select drop down without doing anything.
            if (!optionIsSelected) {
                openSelect.find('.overlay-toggle').click();
                tabToBehaviour('next', false, openSelect);
                return;
            }

            // If the select is part of a date form item we check if all the dates are selected.
            if (isDateFormItem) {
                dateSelectCheckComplete(dateFormItem);
            }

            // Close the selected drop down with the selected option selected and tab to the next form element.
            selectedOption.click();
            tabToBehaviour('next', false, openSelect);
        }

        // Makes the selected option always appear in the middle of the option list while scrollable.
        function setScrollPositionSelectOverlay(openSelect, option) {
            var options = openSelect.find('.options');
            if (option.length) {
                options.scrollTop(options.scrollTop() + option.position().top - options.height()/2 + option.height()/2);
            }
        }

// DATE SELECT OVERLAY

    // ADD BEHAVIOUR TO DATE SELECT

        // Adds functionality specific to the date select form item.
        function dateSelectBehaviour() {

            // Checks if there are pre-filled date select form items and make sure that the show as complete.
            $('.form-item.date').each(function() {
                dateSelectCheckComplete($(this));
            });
            $('.form-item.date .form-item .select-overlay .option').click(function() {
                var dateFormItem = $(this).closest('.form-item.date');
                dateSelectCheckComplete(dateFormItem);
            });
        }

        // Checks if the date select form item is fully filled in and gives it the class complete.
        function dateSelectCheckComplete(dateFormItem) {
            if (dateSelectCheckTouched(dateFormItem)) {
                dateFormItem.addClass('complete');
                var resetValidation = LiveValidation(dateFormItem);
                resetValidation.reset();
            }
        }

        // Checks if the select form items in the date form item are touched/used.
        function dateSelectCheckTouched(dateFormItem) {
            var allTouched = true;
            dateFormItem.find('.form-item.select').each(function() {
                var isTouched = $(this).hasClass('touched');
                if (!isTouched) {
                    allTouched = false;
                }
            });
            return allTouched;
        }

// READ ONLY PREVENT FOCUS

    function ReadOnly() {

        this.initialize = function() {
            var readOnlyInputs = $('input[readonly], textarea[readonly]');
            readOnlyInputs.on('focus', function() {
                preventFocus($(this));
            });
        };

        var preventFocus = function(inputElement) {
            inputElement.trigger('blur');
        };

    }

// ADD FAKE TAB INDEX

    // New tab index that works with all the custom inputs and normal input's in the forms.
    function fakeTabIndex() {

        // Adds attribute identifier so we can keep track of the artificial tab index.
        addFakeTabIndex();

        // Add functionality to the fake tab index. Tab through the form inputs.
        fakeTabIndexBehaviour();

        removeFocusedStateOnClick();

    }

    // Adds attribute identifier so we can keep track of the artificial tab index.
    function addFakeTabIndex() {
        var tabIndex = 0;
        $('.form-item').each(function() {
            var currentFormItem = $(this);
            var isDateWrapper = currentFormItem.hasClass('date');
            var isFileFormItem = currentFormItem.hasClass('file');

            // Prevent the date form item from getting a tab index as it is a wrapper.
            if (isDateWrapper || isFileFormItem) {
                return;
            }

            // Add the fake tab index to the form element.
            tabIndex++;
            currentFormItem.attr('be-tab-index', tabIndex);
        });
    }

    // Add functionality to the fake tab index. Tab through the form inputs.
    function fakeTabIndexBehaviour() {
        $(document).keydown(function(e) {
            var code = e.keyCode || e.which;

            // If the tab key is pressed
            if (code == '9') {

                // If the tab key and shift key is pressed.
                if (e.shiftKey) {
                    tabKeyBehaviour('previous', e);

                // If only the tab key is pressed.
                } else {
                    tabKeyBehaviour('next', e);
                }
            }
        });
    }

    // Behaviour attached to the tab key.
    // direction: This parameter can be 'previous' or 'next'.
    // event: This parameter is the event trigger (key press).
    function tabKeyBehaviour(direction, event) {
        var input = $('input:focus');
        var textarea = $('textarea:focus');
        var select = $('.form-item.select.open');
        var datePicker = $('.date-picker-popup:visible');

        // Find out if one of the form inputs is active and add tab behaviour.
        if (input.length) {
            tabBehaviour(direction, event, input);
        }
        else if (textarea.length) {
            tabBehaviour(direction, event, textarea);
        }
        else if (select.length) {
            tabBehaviour(direction, event, select);
        }
        else if (datePicker.length) {
            tabBehaviour(direction, event, datePicker);
        }
    }

    // Behaviour that takes care of adding the right tab behaviour for the specific active form input.
    // activeElement: The element that is currently active/has focus.
    function tabBehaviour(direction, event, activeElement) {
        var formItem = activeElement.closest('.form-item');

        // Check for the type of form-item so that we attach the right tab off functionality.
        if (formItem.hasClass('radio')) {
            tabOffRadio(direction, event, activeElement);
        } else if (formItem.hasClass('checkbox')) {
            tabOffCheckbox(direction, event, activeElement);
        } else if (formItem.hasClass('select')) {
            tabOffSelect(direction, event, activeElement);
        } else if (formItem.hasClass('date-picker')) {
            tabOffDatePicker(direction, event, activeElement);
        } else if (formItem.hasClass('submit')) {
            tabOffSubmit(direction, event, activeElement);
        } else {
            tabOffText(direction, event, activeElement);
        }
    }

    // TAB OFF INPUT

        // Tab off functionality for radio inputs.
        function tabOffRadio(direction, event, activeElement) {
            activeElement.blur();
            tabToBehaviour(direction, event, activeElement);
        }

        // Tab off functionality for checkbox inputs.
        function tabOffCheckbox(direction, event, activeElement) {
            activeElement.blur();
            tabToBehaviour(direction, event, activeElement);
        }

        // Tab off functionality for select inputs.
        function tabOffSelect(direction, event, activeElement) {
            var dateFormItem = activeElement.closest('.form-item.date');
            var isDateFormItem = dateFormItem.length;
            activeElement.find('.overlay-toggle').click();
            activeElement.change();

            // If the select is part of a date form item we check if all the dates are selected.
            if (isDateFormItem) {
                dateSelectCheckComplete(dateFormItem);
            }

            // Behaviour that takes care of tabbing to the next or previous form input.
            tabToBehaviour(direction, event, activeElement);
        }

        // Tab off functionality for date picker inputs.
        function tabOffDatePicker(direction, event, activeElement) {
            activeElement.closest('.form-item').find('.toggle').click();
            tabToBehaviour(direction, event, activeElement);
        }

        // Tab off functionality for submit inputs.
        function tabOffSubmit(direction, event, activeElement) {
            activeElement.blur();
            tabToBehaviour(direction, event, activeElement);
        }

        // Tab off functionality for text inputs.
        function tabOffText(direction, event, activeElement) {
            activeElement.blur();
            tabToBehaviour(direction, event, activeElement);
        }

    // Behaviour that takes care of tabbing to the next or previous form input.
    function tabToBehaviour(direction, event, activeElement) {
        var tabToElementIndex = findTabToElement(direction, activeElement);
        var tabToFormItem = $('.form-item[be-tab-index=' + tabToElementIndex + ']');

        // If no next or previous form-item exists, maintain the default tab behaviour.
        if (!tabToElementIndex) {
            return;
        }

        // Prevent default tab behaviour as we're replacing the default tab behaviour.
        if (event) {
            event.preventDefault();
        }

        // Check for the type of form-item so that we attach the right tab on functionality.
        if (tabToFormItem.hasClass('radio')) {
            tabOnRadio(tabToFormItem);
        } else if (tabToFormItem.hasClass('checkbox')) {
            tabOnCheckbox(tabToFormItem);
        } else if (tabToFormItem.hasClass('select')) {
            tabOnSelect(tabToFormItem);
        } else if (tabToFormItem.hasClass('date-picker')) {
            tabOnDatePicker(tabToFormItem);
        } else if (tabToFormItem.hasClass('submit')) {
            tabOnSubmit(tabToFormItem);
        } else {
            tabOnText(tabToFormItem);
        }
    }

    // Find the element that is next or previous in line to tab to.
    function findTabToElement(direction, activeElement) {
        var numberOfFormItems = $(document).find('.form-item').length;
        var tabIndexActive = activeElement.closest('.form-item').attr('be-tab-index');
        if (direction == "next") {
            var tabToIndex = parseInt(tabIndexActive) + 1;
            for (var tabIndex = tabToIndex; tabIndex < (numberOfFormItems + 1); tabIndex++) {
                if (isValidTabToElement(tabIndex)) {
                    tabToIndex = tabIndex;
                    return tabToIndex;
                }
            }
            return false;
        } else if (direction == "previous") {
            var tabToIndex = parseInt(tabIndexActive) - 1;
            for (var tabIndex = tabToIndex; tabIndex > 0; tabIndex--) {
                if (isValidTabToElement(tabIndex)) {
                    tabToIndex = tabIndex;
                    return tabToIndex;
                }
            }
            return false;
        }
    }

    function isValidTabToElement(tabIndex) {
        var tabToFormItem = $('.form-item[be-tab-index=' + tabIndex + ']');
        var hasDisabledInput = tabToFormItem.find('[disabled]').length;
        var isDatePicker = tabToFormItem.is('.date-picker');
        var isReadOnlyInput = tabToFormItem.find('[readonly]').length && !isDatePicker;
        var isVisibleFormItem = tabToFormItem.is(':visible');
        return !isReadOnlyInput && !hasDisabledInput && isVisibleFormItem;
    }

    // TAB ON INPUT

        // Tab on functionality for radio inputs.
        function tabOnRadio(tabToFormItem) {
            var checkedRadio = tabToFormItem.find('.checked input[type=radio]');
            var checkedRadioExists = checkedRadio.length;
            if (checkedRadioExists) {
                checkedRadio.focus();
            } else {
                var firstRadioButton = tabToFormItem.find('input[type=radio]:first');
                firstRadioButton.focus();
            }
            tabIndexToggleFocus(tabToFormItem);
        }

        // Tab on functionality for checkbox inputs.
        function tabOnCheckbox(tabToFormItem) {
            tabToFormItem.find('input[type=checkbox]:first').focus();
            tabIndexToggleFocus(tabToFormItem);
        }

        // Tab on functionality for select inputs.
        function tabOnSelect(tabToFormItem) {
            var optionIsSelected = tabToFormItem.find('.option.selected').length;
            tabToFormItem.find('.overlay-toggle').click();
            if (!optionIsSelected) {
                var firstOption = tabToFormItem.find('.option:first-child');
                var optionText = firstOption.text();
                var realOptionValue = tabToFormItem.find('option').filter(function() {
                    return $(this).text() === optionText;
                }).attr('value');
                firstOption.addClass('selected');
                tabToFormItem.find('.overlay-toggle .text').text(optionText);
                changeOptionSelect(tabToFormItem, realOptionValue);
                setTouchedSelect(tabToFormItem);
            }

            // Makes the selected option always appear in the middle of the option list while scrollable.
            var selectedOption = tabToFormItem.find('.option.selected');
            setScrollPositionSelectOverlay(tabToFormItem, selectedOption);
            tabIndexToggleFocus(tabToFormItem);
        }

        // Tab on functionality for date picker inputs.
        function tabOnDatePicker(tabToFormItem) {
            tabToFormItem.closest('.form-item').find('.toggle').click();
            tabIndexToggleFocus(tabToFormItem);
        }

        // Tab on functionality for submit inputs.
        function tabOnSubmit(tabToFormItem) {
            tabToFormItem.find('input[type=submit]').focus();
            tabIndexToggleFocus(tabToFormItem);
        }

        // Tab on functionality for text inputs.
        function tabOnText(tabToFormItem) {
            tabToFormItem.find('input, textarea').focus();
            tabIndexToggleFocus(tabToFormItem);
        }

        function tabIndexToggleFocus(formItem) {
            var focusClassName = 'focused';
            $('.form-item').removeClass(focusClassName);
            formItem.addClass(focusClassName);
        }

        function removeFocusedStateOnClick() {
            $('.form-item').click(function() {
                var focusClassName = 'focused';
                $('.form-item').removeClass(focusClassName);
            });
        }

// INPUT VALUE MANIPULATION

    function InputValueManipulation() {

        var scope = this;

        this.initialize = function() {

            $(
                'input[type=text], ' +
                'input[type=number], ' +
                'input[type=password], ' +
                'textarea'
            ).each(function() {

                trimFormValue($(this));

            });

            $(
                '.form-item.electricity input, ' +
                '.form-item.electricity-business input, ' +
                '.form-item.gas input, ' +
                '.form-item.gas-business input'
            ).each(function() {

                trimLeadingZeroes($(this));

            });

            $(
                '.form-item.amount input'
            ).each(function() {

                var input = $(this);

                dotToComma(input);
                addDecimals(input);
                trimStartingZero(input);

            });
        };

        var trimFormValue = function(input) {

            input.on('blur', function () {

                var trimmedValue = trimSpaces(input.val());

                input.val(trimmedValue);

            });

        };

        var trimLeadingZeroes = function(input) {

            input.on('blur', function () {

                var trimmedValue = input.val().replace(/^0+/, '');

                input.val(trimmedValue);

            });

        };

        var dotToComma = function(input) {

            input.keydown(function (e) {

                var key = e.charCode || e.keyCode || 0;

                if (key === 190 || key === 110 || key === 188) {

                    var inputValue = input.val();
                    var cursorPosition = e.target.selectionStart;
                    var slicedInputValueBasedOnCursorPosition = inputValue.slice(0, cursorPosition);
                    var noCommaInInputValue = inputValue.indexOf(',') === -1;

                    if (noCommaInInputValue && cursorPosition !== 0) {

                        var newInputValue = inputValue.replace(slicedInputValueBasedOnCursorPosition, slicedInputValueBasedOnCursorPosition + ',');
                        var newCursorPosition = cursorPosition + 1;

                        input.val(newInputValue);
                        input.focus();
                        input.get(0).setSelectionRange(newCursorPosition, newCursorPosition);

                    }

                    e.preventDefault();

                }

            });

        };

        var addDecimals = function(input) {

            input.on('blur', function () {

                var value = input.val();
                var lastCharacter = value.substr(-1);

                if (lastCharacter === ",") {
                    input.val(value + '00')
                }

            });

        };

        var trimStartingZero = function(input) {

            input.on('blur', function () {

                var inputValue = input.val();
                var startingZeroRegex = /^0+/;
                var startingZeroWithTrailingNumberRegex = /^0+[1-9]/;
                var startingZeroWithTrailingNumber = startingZeroWithTrailingNumberRegex.test(inputValue);
                var newValue = inputValue.replace(startingZeroRegex, "0");

                if (startingZeroWithTrailingNumber) {

                    newValue = inputValue.replace(startingZeroRegex, "");

                }

                input.val(newValue);

            });

        }

    }

// TYPING PREVENTION

    function PreventInput() {

        var scope = this;

        this.initialize = function() {

            $(
                '.form-item.numbers input, ' +
                '.form-item.decimals input, ' +
                '.form-item.initials input, ' +
                '.form-item.postal-code input, ' +
                '.form-item.email input, ' +
                '.form-item.iban input, ' +
                '.form-item.password input, ' +
                '.form-item.monthly-amount input, ' +
                '.form-item.not-zero input, ' +
                '.form-item.house-number input,' +
                '.form-item.amount input,' +
                '.form-item.icc-code input,' +
                '.form-item.nen-initials input'
            ).each(function() {
                scope.spaces($(this));
            });

            $(
                '.form-item.amount input'
            ).each(function() {

                var input = $(this);

                scope.allButNumbers(input);
                scope.decimals(input, 2);

            });

            $(
                '.form-item.phone input, ' +
                '.form-item.phone-all-in-one input,' +
                '.form-item.phone-all-in-one-no-mobile input,' +
                '.form-item.icc-code input,' +
                '.form-item.electricity input, ' +
                '.form-item.gas input, ' +
                '.form-item.electricity-business input, ' +
                '.form-item.gas-business input, ' +
                '.form-item.reading-electricity input, ' +
                '.form-item.reading-gas input, ' +
                '.form-item.kvk input, ' +
                '.form-item.monthly-amount input, ' +
                '.form-item.not-zero input, ' +
                '.form-item.house-number input'
            ).each(function() {
                scope.allButIntegers($(this));
            });

            $(
                '.form-item[paired].email input[type=text], ' +
                '.form-item[paired].email input[type=email], ' +
                '.form-item[paired].password input[type=text]'
            ).each(function() {
                scope.copyPaste($(this));
            });

        };

        this.spaces = function(inputElement) {
            scope.singleKey(inputElement, 32);
            inputElement.keyup(function() {
                let searchBreak = /\s/g;
                let removeBreak = '';
                let scopeInput = inputElement;
                let scopeKeyfield = scopeInput.val();
                let scopeKeyInput = scopeKeyfield.replace(searchBreak,removeBreak);
                scopeInput.val(scopeKeyInput);
            });
        };

        this.allButIntegers = function(inputElement) {
            inputElement.keydown(function (e) {
                var key = e.charCode || e.keyCode || 0;

                if (e.shiftKey) {
                    e.preventDefault();
                }

                // Allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
                if (!e.ctrlKey) {
                    return (
                        key === 8 ||
                        key === 9 ||
                        key === 13 ||
                        key === 46 ||
                        (key >= 37 && key <= 40) ||
                        (key >= 48 && key <= 57) ||
                        (key >= 96 && key <= 105)
                    );
                }
            });
        };

        this.allButNumbers = function(inputElement) {
            inputElement.keydown(function (e) {
                var key = e.charCode || e.keyCode || 0;

                if (e.shiftKey) {
                    e.preventDefault();
                }

                // Allow backspace, tab, delete, enter, arrows, commas, dots, numbers and keypad numbers ONLY
                if (!e.ctrlKey) {
                    return (
                        key === 8 ||
                        key === 9 ||
                        key === 13 ||
                        key === 46 ||
                        key === 190 ||
                        key === 188 ||
                        key === 110 ||
                        (key >= 37 && key <= 40) ||
                        (key >= 48 && key <= 57) ||
                        (key >= 96 && key <= 105)
                    );
                }
            });
        };

        this.copyPaste = function(inputElement) {
            inputElement.bind('copy paste cut', function (e) {
                e.preventDefault();
            });
        };

        this.singleKey = function(inputElement, keyCode) {
            inputElement.keydown(function(e) {
                if (e.which === keyCode) {
                    return false;
                }
            });
        };

        this.decimals = function(inputElement, decimalAmount) {
            inputElement.keydown(function(e) {

                var inputValue = inputElement.val();
                var currentDecimalAmount = getDecimalCount(inputValue)
                var reachedMaxDecimalAmount = currentDecimalAmount === decimalAmount;

                if (!reachedMaxDecimalAmount) {
                    return;
                }

                var cursorPosition = e.target.selectionStart;

                if (!isAlteringDecimal(cursorPosition, currentDecimalAmount, inputValue)) {
                    return
                }

                var key = e.charCode || e.keyCode || 0;

                return (
                    key === 8 ||
                    key === 46 ||
                    (key >= 37 && key <= 40)
                );

            });
        };

        var getDecimalCount = function (value) {

            var noCommaInInputValue = value.indexOf(',') === -1;

            if (noCommaInInputValue) {

                return 0;

            }

            return value.split(",")[1].length;

        };

        var isAlteringDecimal = function(position, decimals, value) {

            var characterCount = value.length;

            if (decimals < 1) {
                return false;
            }

            if ((characterCount - (decimals + 1)) >= position) {
                return false;
            }

            return true;

        };

    }


// CHECKED RADIO BUTTONS AND CHECKBOXES

    function CheckboxAndRadioButtonFunctionality() {

        var self = this;

        this.initialize = function() {
            self.toggleCheckedClass($('input[type=checkbox]:checked, input[type=radio]:checked'));
            self.toggleDisabledClass($('input[type=checkbox]:disabled, input[type=radio]:disabled'));
            $('input[type=checkbox], input[type=radio]').on('change', function() {
                var input = $(this);
                self.toggleCheckedClass(input);
                self.toggleDisabledClass(input);
            });
        };

        this.toggleCheckedClass = function(inputElement) {
            var label = inputElement.closest('label');
            var isChecked = inputElement.is(':checked');
            if (isChecked) {
                inputElement.closest('.form-item').find('label').removeClass('checked');
                label.addClass('checked');
                addTouchedClass(inputElement);
                return;
            }
            inputElement.closest('.form-item').find('label').removeClass('checked');
        };

        this.toggleDisabledClass = function(inputElement) {
            var label = inputElement.closest('label');
            var isDisabled = inputElement.is(':disabled');
            if (isDisabled) {
                inputElement.closest('.form-item').find('label').removeClass('disabled');
                label.addClass('disabled');
                return;
            }
            inputElement.closest('.form-item').find('label').removeClass('disabled');
        };

        var addTouchedClass = function(inputElement) {
            var touchedClass = 'touched';
            var formItem = inputElement.closest('.form-item');
            var isTouched = formItem.hasClass('touched');
            if (!isTouched) {
                formItem.addClass(touchedClass);
            }
        };

    }


// PLACE HOLDER FORM ITEMS

    function PlaceholderFormItems() {

        var scope = this;

        this.initialize = function() {

            var placeholders = $('.form-item.placeholder label, .form-item.smart-placeholder label, .form-item.smart-placeholder-mobile label');
            var textInputs = placeholders.closest('.form-item').find('input, textarea');

            placeholders.each(function() {

                var inputElement = $(this).closest('.form-item').find('input, textarea');

                scope.toggleActive(inputElement);

            });

            placeholders.click(function() {

                var inputElement = $(this).closest('.form-item').find('input, textarea');

                inputElement.focus();
                scope.toggleActive(inputElement);

            });

            textInputs.on('click focus', function() {

                var textInput = $(this);

                scope.toggleActive(textInput);
                textInput.closest('.form-item').addClass('focus');

            });

            textInputs.on('change input', function() {

                scope.toggleActive($(this));

            });

            textInputs.on('blur', function() {

                var textInput = $(this);

                scope.toggleActive(textInput);
                textInput.closest('.form-item').removeClass('focus');

            });

        };

        this.toggleActive = function(inputElement) {

            var formItem = inputElement.closest('[class*="placeholder"]');
            var isEmpty = inputElement.val() == "";

            if (isEmpty) {

                formItem.removeClass('active');
                return;

            }

            formItem.addClass('active');

        };

    }

// ADD FORM SPINNER

    // Add spinner to form and show the spinner when form is valid.
    // spinnerTrigger: The element that triggers this method (css/jQuery route string or this).
    function formSpinnerBehaviour(spinnerTrigger, form) {
        var formInvalid = form.find('.form-item.invalid').length;
        if (!formInvalid) {
            var formSpinner = new Spinner(spinnerTrigger, form);
            formSpinner.add();
            formSpinner.show();
        }
    }

// RESET FORM

    // Resets all the form elements within a form
    function resetForm(formElement) {
        var inputElement = formElement.find('input[type=text], input[type=number], input[type=password], textarea');
        var checkboxElement = formElement.find('input[type=radio]');
        var radioButtonElement = formElement.find('input[type=checkbox]');
        var selectElement = formElement.find('select');
        // TODO Budget: Also reset the image/file upload fields.
        resetTextInput(inputElement);
        resetCheckbox(checkboxElement);
        resetRadioButton(radioButtonElement);
        resetSelect(selectElement);
        var resetValidation = new LiveValidation(formElement);
        resetValidation.massReset();
    }

    // Reset text input fields
    function resetTextInput(inputElement) {
        var PlaceholderFormItemsFunctionality = new PlaceholderFormItems();
        inputElement.val('');
        inputElement.each(function() {
            PlaceholderFormItemsFunctionality.toggleActive(inputElement);
        });
    }

    // Reset checkboxes
    function resetCheckbox(checkboxElement) {
        checkboxElement.prop( "checked", false );
    }

    // Reset radio buttons
    function resetRadioButton(radioButtonElement) {
        radioButtonElement.attr('checked',false);
    }

    // Reset select dropdowns
    function resetSelect(selectElement) {
        selectElement.prop('selectedIndex',0);
        selectElement.closest('.form-item').removeClass('touched');
        selectElement.siblings('.select-overlay').find('.option.selected').removeClass('selected');
        populateSelectAddPlaceholder(selectElement.closest('.form-item'));
        selectElement.each(function() {
            var isPlaceholder = $(this).closest('.placeholder').length;
            if (isPlaceholder) {
                $(this).closest('.form-item').removeClass('active');
            }
            var resetValidation = new LiveValidation($(this));
            resetValidation.reset();
        });
    }

// DATE PICKER

    // POPULATE DATE PICKER FORM ITEM

        // Add date picker and functionality to the date picker form item.
        function datePickerBehaviour() {
            $('.form-item.date-picker').each(function() {
                var datePicker = $(this);
                populateDatePicker(datePicker);
                var datePickerPopup = datePicker.find('.date-picker-popup');
                var startDate = datePicker.find('input').attr('start-date');
                var endDate = datePicker.find('input').attr('end-date');
                var weekendEnabled = datePicker.hasClass('include-weekends');
                datePickerClickAway(datePickerPopup);
                addDatePicker(datePickerPopup, startDate, endDate, weekendEnabled);
                datePickerInputBehaviour(datePicker);
            });
        }

        // Add date picker to date picker form item.
        function populateDatePicker(datePicker) {
            datePicker.find('input[type=text]').wrap('<span></span>');
            datePicker.children('span').append(
                '<span class="toggle"></span>' +
                '<div class="date-picker-popup"></div>'
            );
        }

        // Add behaviour to the date Picker form item's input field.
        function datePickerInputBehaviour(datePicker) {
            var datePickerInput = datePicker.find('input[type=text]');
            datePickerInput.prop('readonly', true);
            datePicker.find('.toggle').click(function () {
                var datePickerIsDisabled = datePickerInput.is('[disabled]');
                if (!datePickerIsDisabled) {
                    var datePickerPopup = $(this).closest('.date-picker').find('.date-picker-popup');
                    var datePickerPopupIsVisible = datePickerPopup.is(':visible');
                    if (!datePickerPopupIsVisible) {
                        datePickerPopup.slideDown(200);
                        return;
                    }
                    datePickerPopup.slideUp(200);
                }
            });
        }

        // Date picker click away functionality
        function datePickerClickAway(datePickerPopup) {
            $(document).click(function(event) {
                if (!$(event.target).closest('.date-picker-popup').length && !$(event.target).hasClass('toggle')) {
                    datePickerPopup.slideUp(200);
                }
            });
        }

    // SETUP METHOD

        // datePickerElement: This element is the element you want to change into the datepicker (enter a element: $('elementName')).
        // startDate: This is the minimum/starting date that the user can pick from ( enter a string: 'dd/mm/yyyy').
        // endDate: This is the maximum/ending date that the user can pick ( enter a string: 'dd/mm/yyyy').
        // disableWeekend: Choose whether weekends can be selected or not (true or false(boolean)).
        function addDatePicker(datePickerElement, startDate, endDate, weekendEnabled) {
            datePickerElement.datePicker({
                startDate: startDate,
                endDate: endDate,
                showYearNavigation: false,
                inline: true
            }).bind('dateSelected', function(e, selectedDate) {
                var date = selectedDate.asString();
                var popup = $(this).closest('.date-picker-popup');
                var datePicker = popup.closest('.date-picker');
                var input = datePicker.find('input[type=text]');
                popup.closest('.date-picker').addClass('active');
                input.val(date).trigger('input').blur();
                popup.fadeOut();
            });
            var popup = datePickerElement.closest('.date-picker-popup');
            popup.find('*').click(function() {
                datePickerDisableWeekend(popup, weekendEnabled);
            });
            datePickerDisableWeekend(popup, weekendEnabled);
        }

        // Disable the weekends in the date picker for being picked.
        function datePickerDisableWeekend(popup, weekendEnabled) {
            if (!weekendEnabled) {
                popup.find('tbody .weekend').addClass('disabled');
            }
        }

// INITIALS OPTIMISER

    function InitialsOptimiser() {

        this.initialize = function() {

            $('.form-item.initials input, .form-item.nen-initials input').on('blur', function () {

                var input = $(this);
                var initials = formatInitials(input);

                input.val(initials);

            });

        };

        var formatInitials = function(input) {

            var inputValue = input.val();

            if (!inputValue.length || /\d/.test(inputValue)) {

                return inputValue;

            }

            var trimmedValue = inputValue.trim();
            var valueWithoutDots = trimmedValue.replace(/\./g, '');
            var valueWithUppercase = valueWithoutDots.toUpperCase();
            var valueArray = valueWithUppercase.split("");

            return valueArray.join(".") + ".";

        };

    }

// IBAN VIEW OPTIMISER

    function IbanOptimiser() {

        var optimiser = this;
        var whiteSpaceLocations = [2,4,8,12,16];

        this.initialize = function() {

            var ibanInput = $('.form-item.iban input');

            ibanInput.on('blur', function() {
                var inputElement = $(this);
                var inputValue = inputElement.val();
                var updatedInputValue = optimiser.formatValue(inputValue);

                updateInputValue(inputElement, updatedInputValue);
            });

            ibanInput.on('click focus', function() {
                var inputElement = $(this);
                var inputValue = inputElement.val();
                var updatedInputValue = optimiser.spaceInputValue(inputValue);

                updateInputValue(inputElement, updatedInputValue);
            });
        };

        this.formatValue = function(ibanString) {
            var inputValue = StringManipulation.removeSpaces(ibanString);
            var sortedWhiteSpaceLocations = SortArray.descending(whiteSpaceLocations);

            for (var i in sortedWhiteSpaceLocations) {
                if (inputValue.length > sortedWhiteSpaceLocations[i]) {
                    var startOfValue = inputValue.slice(0, sortedWhiteSpaceLocations[i]);
                    var endOfValue = inputValue.slice(sortedWhiteSpaceLocations[i]);

                    inputValue = startOfValue + ' ' + endOfValue;
                }
            }

            return inputValue;
        };

        var updateInputValue = function(inputElement, ibanString) {
            inputElement.val(ibanString.toUpperCase());
        };

        this.spaceInputValue = function(ibanString) {
            var inputValue = StringManipulation.removeSpaces(ibanString);

            return inputValue;
        };

        this.updateTrimmedValue = function(inputElement) {
            var inputValue = StringManipulation.removeSpaces(inputElement.val());
            inputElement.val(inputValue);
        };

    }

// PASSWORD STRENGTH INDICATOR

    // Add a password indicator to the password form item when the user inputs a value.
    function indicatePasswordStrength() {

        // Update the password strength indicator when the user adds or removes a character in a password field.
        $('.form-item.show-indicator input[type=password]').on('input', function() {
            var field = $(this);
            var value = field.val();
            var passwordStrengthIndication = checkPasswordStrength(value);

            // Don't show the indicator if the password minimum or maximum number of characters are reached.
            if (value.length < 8 || value.length > 39) {
                field.closest('.form-item').find('.strength-indicator').remove();
                return;
            }

            // Prevent making multiple indicator elements.
            if (!field.closest('.form-item').find('.strength-indicator').length) {

                // Populate the password input with a password strength indicator.
                createPasswordStrengthIndicator(field);
            }

            var indicator = field.closest('.form-item').find('.strength-indicator');
            var strong = Translator.trans('validation.indicator.strong');
            var decent = Translator.trans('validation.indicator.decent');
            var weak = Translator.trans('validation.indicator.weak');

            if (passwordStrengthIndication < 2) {
                indicator.text(weak).attr('strength-indication', passwordStrengthIndication);
                return;
            }
            if (passwordStrengthIndication < 4) {
                indicator.text(decent).attr('strength-indication', passwordStrengthIndication);
                return;
            }
            indicator.text(strong).attr('strength-indication', passwordStrengthIndication);
        });
    }

    // Populate the password input with a password strength indicator.
    function createPasswordStrengthIndicator(passwordField) {
        passwordField.after('<span class="strength-indicator"></span>');
    }

    // returns a integer on a scale of 1 to 5 which indicates how strong a password is.
    // The bigger the number the stronger the password.
    function checkPasswordStrength(string) {
        var hasLowercase = /^(?=.*[a-z]).+$/.test(string);
        var hasUppercase = /^(?=.*[A-Z]).+$/.test(string);
        var hasNumbers = /^(?=.*\d).+$/.test(string);
        var hasSpecialCharacters = /^(?=.*(_|[^\w])).+$/.test(string);
        var hasEnoughCharacters = string.length > 11;
        return hasLowercase + hasUppercase + hasNumbers + hasSpecialCharacters + hasEnoughCharacters;
    }

// STEPS INDICATOR

    function StepsIndicator() {

        this.initialize = function() {
            var stepsIndicators = $('.steps-indicator');
            stepsIndicators.each(function() {
                var stepsIndicator = $(this);
                setStepDescription(stepsIndicator);
                setStepCountClass(stepsIndicator);
            });
        };

        var descriptionsAreSet = function(stepsIndicator) {
            var steps = stepsIndicator.find('.step');
            var isDescriptionSet = true;
            steps.each(function() {
                var step = $(this);
                var stepHasDescription = step.is('[step-description]');
                if (!stepHasDescription) {
                    isDescriptionSet = false;
                    return false;
                }
            });
            return isDescriptionSet;
        };

        var generateHeader = function(stepsIndicator) {
            var headerExists = stepsIndicator.find('.step-header').length;
            if (!headerExists) {
                stepsIndicator.prepend('<h2 class="step-header informal-3"></h2>');
            }
        };

        var populateHeader = function(stepsIndicator) {
            var activeStep = stepsIndicator.find('.step.active');
            var stepHeader = stepsIndicator.find('.step-header');
            var step = Translator.trans('defaultTerms.step');
            var stepPositionNumber = getStepPosition(stepsIndicator);
            var stepPositionNumberString = stepPositionNumber.toString();
            var stepDescription = activeStep.attr('step-description');
            stepHeader.text(step + " " + stepPositionNumberString + ": " + stepDescription);
        };

        var setStepDescription = function(stepsIndicator) {
            var hasActiveStep = stepsIndicator.find('.step.active').length;
            if (descriptionsAreSet(stepsIndicator) && hasActiveStep) {
                generateHeader(stepsIndicator);
                populateHeader(stepsIndicator);
            }
        };

        var getStepPosition = function(stepsIndicator) {
            var steps = stepsIndicator.find('.step');
            var stepCount = 0;
            steps.each(function() {
                var step = $(this);
                var stepIsActive = step.hasClass('active');
                stepCount++;
                if (stepIsActive) {
                    return false;
                }
            });
            return stepCount;
        };

        var setStepCountClass = function(stepsIndicator) {
            var steps = stepsIndicator.find('.step');
            var stepCount = steps.length;
            var stepCountString = stepCount.toString();
            var stepCountClass = "step-amount-" + stepCountString;
            stepsIndicator.addClass(stepCountClass);
        };

        this.stepForward = function(stepsIndicator) {
            setNewForwardState(stepsIndicator);
            setStepDescription(stepsIndicator);
        };

        this.stepBackward = function(stepsIndicator) {
            setNewBackwardState(stepsIndicator);
            setStepDescription(stepsIndicator);
        };

        var setNewForwardState = function(stepsIndicator) {
            var activeStep = stepsIndicator.find('.step.active');
            var activeStepExists = activeStep.length;
            var activeStepIsLast = activeStep.is('.step:last');
            if (!activeStepExists || activeStepIsLast) {
                return false;
            }
            activeStep.removeClass('active').addClass('done').next('.step').addClass('active');
        };

        var setNewBackwardState = function(stepsIndicator) {
            var activeStep = stepsIndicator.find('.step.active');
            var activeStepExists = activeStep.length;
            var activeStepIsFirst = activeStep.is('.step:first');
            if (!activeStepExists || activeStepIsFirst) {
                return false;
            }
            activeStep.removeClass('active').prev('.step').removeClass('done').addClass('active');
        };

    }

// VALIDATION

    // Reset validation on input, change or click.
    function resetValidation() {
        $(
            '.form-item input[type=text], ' +
            '.form-item input[type=number], ' +
            '.form-item input[type=password], ' +
            '.form-item input[type=email], ' +
            '.form-item textarea'
        ).on('input', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
        });
        $('.form-item.required input[type=radio], .form-item.required input[type=checkbox], .form-item.required select').on('change', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
        });
        $('.form-item input[type=file]').on('click', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
        });
    }

    // Set al the default form validations
    function defaultLiveValidation() {

        // Validation for form elements that can only consist of integers.
        $('.form-item.numbers input').on('blur', function() {
            numberValidation($(this));
        });

        // Validation for form elements that can only consist of numbers with decimals allowed.
        $('.form-item.decimals input').on('blur', function () {
            decimalValidation($(this));
        });

        // Validation for form elements that can only consist of letters without special characters.
        $('.form-item.letters input').on('blur', function () {
            letterValidation($(this));
        });

        // Validation for form elements that can only consist of letters.
        $('.form-item.all-letters input').on('blur', function () {
            allLetterValidation($(this));
        });

        // Validation for gas form elements.
        $('.form-item.gas input').on('blur', function () {
            gasValidation($(this));
        });

        // Validation for electricity form elements.
        $('.form-item.electricity input').on('blur', function () {
            electricityValidation($(this));
        });

        // Validation for gas business form elements.
        $('.form-item.gas-business input').on('blur', function () {
            gasBusinessValidation($(this));
        });

        // Validation for electricity business form elements.
        $('.form-item.electricity-business input').on('blur', function () {
            electricityBusinessValidation($(this));
        });

        // Validation for house number form elements.
        $('.form-item.house-number input').on('blur', function () {
            houseNumberValidation($(this));
        });

        // Validations for name form elements.
        $('.form-item.names input').on('blur', function () {
            namesValidation($(this));
        });

        // Validations for name form elements.
        $('.form-item.initials input').on('blur', function () {
            initialsValidation($(this));
        });

        // Validations for postal code form elements.
        $('.form-item.postal-code input').on('blur', function () {
            postalCodeValidation($(this));
        });

        // Validations for email form elements.
        $('.form-item.email input').on('blur', function () {
            emailValidation($(this));
        });

        // Validations for phone number form elements.
        $('.form-item.phone input').on('blur', function () {
            phoneValidation($(this));
        });

        // Validations for all-in-one phone numbers form elements.
        $('.form-item.phone-all-in-one input').on('blur', function () {
            allInOnePhoneValidation($(this));
        });

        // Validations for all-in-one not mobile phone numbers form elements.
        $('.form-item.phone-all-in-one-no-mobile input').on('blur', function () {
            allInOneNotMobilePhoneValidation($(this));
        });

        // Validations for bank account (IBAN) form elements.
        $('.form-item.iban input[type=text]').on('blur', function () {
            ibanValidation($(this));
        });

        // Validations for KvK number form elements.
        $('.form-item.kvk input[type=text], .form-item.kvk input[type=number]').on('blur', function () {
            kvkValidation($(this));
        });

        // Validations for username form elements.
        $('.form-item.username input[type=text]').on('blur', function () {
            usernameValidation($(this));
        });

        // Validations for password form elements.
        $('.form-item.password input[type=password]').on('blur', function () {
            passwordValidation($(this));
        });

        // Validations for house extension form elements.
        $('.form-item.house-extension input').on('blur', function () {
            houseExtensionValidation($(this));
        });

        // Validations for monthly amount form elements.
        $('.form-item.monthly-amount input').on('blur change', function () {
            monthlyAmountValidation($(this));
        });

        // Validations for monthly amount manager form elements.
        $('.form-item.not-zero input').on('blur change', function () {
            notZeroValidation($(this));
        });

        // Validations for document input form elements.
        $('.form-item.document input[type=file]').on('change', function () {
            documentValidation($(this));
        });

        // Validations for image input form elements.
        $('.form-item.image input[type=file]').on('change', function () {
            imageValidation($(this));
        });

        // Validations for paired password input form elements.
        $('.form-item[paired] input[type=password]').on('blur', function() {
            pairedPasswordValidation($(this));
        });

        // Validations for paired email input form elements.
        $('.form-item[paired].email input[type=text], .form-item[paired].email input[type=email]').on('blur', function() {
            pairedEmailValidation($(this));
        });

        // Validations for amount input form elements.
        $('.form-item.amount input[type=text]').on('blur', function() {
            amountValidation($(this));
        });

        // Validations for character input form elements.
        $('.form-item.character input[type=text]').on('blur', function() {
            characterValidation($(this));
        });

        // Validations for ICC CODE
        $('.form-item.icc-code input').on('blur', function() {
            iccCodeValidation($(this));
        });

        // Validations for NEN initials
        $('.form-item.nen-initials input[type=text]').on('blur', function() {
            nenInitialsValidation($(this));
        });

        // Validations for NEN prefix
        $('.form-item.nen-prefix input[type=text]').on('blur', function() {
            nenPrefixValidation($(this));
        });

        // Validations for surname
        $('.form-item.surname input[type=text]').on('blur', function() {
            surnameValidation($(this));
        });

        // Validations for different reason for changing monthly amount
        $('.form-item.max-characters-textarea textarea').on('blur', function() {
            maxCharactersTextareaValidation($(this));
        });

        // Validations max characters
        $('.form-item.max-characters input').on('blur', function () {
            maxCharactersValidation($(this));
        });

        // Validation for form elements that are required to be filled in.
        $(
            '.form-item.required input[type=text], ' +
            '.form-item.required input[type=number], ' +
            '.form-item.required input[type=password], ' +
            '.form-item.required input[type=email], ' +
            '.form-item.required textarea'
        ).on('blur', function () {
            silentRequiredValidation($(this));
        });

        $('.form-item.required input[type=radio], .form-item.required input[type=checkbox]').on('change', function () {
            silentRequiredValidation($(this));
        });

        $('.form-item.date-picker.required input[type=text]').on('blur', function () {
            requiredValidation($(this));
        });

        // Mass validation for when the form is being submitted.
        // Add spinner when massValidation is ran and there are no invalid fields.
        $('form input[type=submit]').on('click', function(triggeredEvent) {
            var form = $(this).closest('form');

            // Run mass validation on the form.
            massValidation(form, triggeredEvent);

            // Stop here if form is invalid.
            if (form.find('.form-item.invalid').length) {
                return false;
            }

            // Add and show the spinner in the form.
            formSpinnerBehaviour($(this), form);

            // Trim whitespaces from iban values.
            form.find('.form-item.iban input').each(function() {
                var optimiseIban = new IbanOptimiser();
                optimiseIban.updateTrimmedValue($(this));
            });

            // Wait for the form to submit so that IE browsers can load the background image of the spinner.
            // Duration is zero as it will perform the submit on the next tick which is enough to have the Background
            // image loaded.
            delayFormSubmit(form, 200);

            return false;
        });

        // Delay form submit.
        function delayFormSubmit(form, duration) {
            setTimeout(function() {
                form.submit();
            }, duration);
        }
    }

    // GENERAL VALIDATION METHODS

        // Validation for form elements that can only consist of integers.
        function numberValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.numbers();
            });
        }

        // Validation for form elements that can only consist of numbers with decimals allowed.
        function decimalValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.numbers('decimal');
            });
        }

        // Validation for form elements that can only consist of letters without special characters.
        function letterValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.letters('noSpecial');
            });
        }

        // Validation for form elements that can only consist of letters.
        function allLetterValidation(inputElements) {
            inputElements.each(function() {
                var validationObject = new LiveValidation(this);
                validationObject.letters('all');
            });
        }

        // Validation for gas form elements.
        function gasValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum(1);
                validationObject.maximum(10000);
                validationObject.numbers();
            });
        }

        // Validation for electricity form elements.
        function electricityValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum(1);
                validationObject.maximum(20000);
                validationObject.numbers();
            });
        }

        // Validation for gas business form elements.
        function gasBusinessValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum(1);
                validationObject.maximum(50000);
                validationObject.numbers();
            });
        }

        // Validation for electricity business form elements.
        function electricityBusinessValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum(1);
                validationObject.maximum(100000);
                validationObject.numbers();
            });
        }

        // Validation for house number form elements.
        function houseNumberValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum(1);
                validationObject.maximum(99999);
                validationObject.numbers();
            });
        }

        // Validations for name form elements.
        function namesValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.names();
            });
        }

        // Validations for initials form elements.
        function initialsValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.initials();
            });
        }

        // Validations for postal code form elements.
        function postalCodeValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.postalCode();
            });
        }

        // Validations for email form elements.
        function emailValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.email();
            });
        }

        // Validations for phone number form elements.
        function phoneValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.phone();
            });
        }

        // Validations for all-in-one phone number form elements.
        function allInOnePhoneValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.allInOnePhone();
            });
        }

        // Validations for all-in-one not mobile phone number form elements.
        function allInOneNotMobilePhoneValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.allInOneNotMobilePhone();
            });
        }

        // Validations for bank account (IBAN) form elements.
        function ibanValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.iBan();
            });
        }

        // Validations for KvK number form elements.
        function kvkValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.kvk();
            });
        }

        // Validations for username form elements.
        function usernameValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.username();
                validationObject.usernameCharacters();
            });
        }

        // Validations for password form elements.
        function passwordValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.minimum('8', 'characters');
                validationObject.maximum('39', 'characters');
            });
        }

        // Validations for house extension form elements.
        function houseExtensionValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.maximum('6', 'characters');
            });
        }

        // Validations for monthly amount form elements.
        function monthlyAmountValidation(inputElements) {
            inputElements.each(function () {
                var inputElement = $(this);
                var formItem = inputElement.closest('.form-item');
                var min = parseInt(formItem.attr('data-minimum'));
                var max = parseInt(formItem.attr('data-maximum'));
                var validationObject = new LiveValidation(inputElement);
                validationObject.minimum(min);
                validationObject.maximum(max);
                validationObject.numbers();
            });
        }

        // Validations for monthly amount manager form elements.
        function notZeroValidation(inputElements) {
            inputElements.each(function () {
                var inputElement = $(this);
                var validationObject = new LiveValidation(inputElement);
                validationObject.minimum(1);
                validationObject.numbers();
            });
        }

        // Validations for document input form elements.
        function documentValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.fileType('document');
                validationObject.fileSize(5000);
            });
        }

        // Validations for image input form elements.
        function imageValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.fileType('image');
                validationObject.fileSize(5000);
            });
        }

        // Validation for paired password input form elements.
        function pairedPasswordValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.paired('wachtwoorden');
            });
        }

        // Validation for paired email input form elements.
        function pairedEmailValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.paired('e-mailadressen');
            });
        }

        // Validation for amount input form elements.
        function amountValidation(inputElements) {
            inputElements.each(function() {
                var validationObject = new LiveValidation(this);
                var inputElement = $(this);
                var min = parseFloat(inputElement.data('min-amount'));
                var max = parseFloat(inputElement.data('max-amount'));
                validationObject.minimum(min);
                validationObject.maximum(max);
            });
        }

        // Validation for characters input form elements.
        function characterValidation(inputElements) {
            inputElements.each(function() {
                var validationObject = new LiveValidation(this);
                var inputElement = $(this);
                var min = parseFloat(inputElement.data('min-characters'));
                var max = parseFloat(inputElement.data('max-characters'));
                validationObject.minimum(min, 'characters');
                validationObject.maximum(max, 'characters');
            });
        }

        // Validation for ICC code input form elements.
        function iccCodeValidation(inputElements) {
            inputElements.each(function() {
                var validationObject = new LiveValidation(this);
                validationObject.iccCode();
            });
        }

        // Validations for NEN initials
        function nenInitialsValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.maximum(64, 'characters');
                validationObject.nenInitials();
            });
        }

        // Validations for NEN prefix
        function nenPrefixValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.nenPrefix();
            });
        }

        // Validations for surname
        function surnameValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.maximum(50, 'characters');
                validationObject.letters('all');
                validationObject.surname();
            });
        }

        // Validations for different reason for changing monthly amount
        function maxCharactersTextareaValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.maximum(255, 'characters');
            });
        }

        // Validations max characters
        function maxCharactersValidation(inputElements) {
            inputElements.each(function () {
                var inputElement = $(this);
                var formItem = inputElement.closest('.form-item');
                var validationObject = new LiveValidation(this);
                var max = parseInt(formItem.attr('data-maximum'));
                validationObject.maximum(max, 'characters');
            });
        }

        // Validation for form elements that are required to be filled in.
        function requiredValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                validationObject.required();
            });
        }

        // Validation for form elements that are required to be filled in but dont show the message or red borders.
        function silentRequiredValidation(inputElements) {
            inputElements.each(function () {
                var validationObject = new LiveValidation(this);
                var validity = validationObject.validate.required();
                if (validity) {
                    validationObject.message.required(validity);
                    return;
                }
                validationObject.reset();
            });
        }

    // MASS VALIDATION

        // Set the form mass validation
        function massValidation(formElement, eventTrigger) {

            // Start validation in progress.
            formElement.addClass('validating');

            // Validation for form elements that can only consist of integers.
            var numberInputs = formElement.find('.form-item.numbers input:enabled');
            numberValidation(numberInputs);

            // Validation for form elements that can only consist of numbers with decimals allowed.
            var decimalInputs = formElement.find('.form-item.decimals input:enabled');
            decimalValidation(decimalInputs);

            // Validation for form elements that can only consist of letters without special characters.
            var letterInputs = formElement.find('.form-item.letters input:enabled');
            letterValidation(letterInputs);

            // Validation for form elements that can only consist of letters.
            var allLetterInputs = formElement.find('.form-item.all-letters input:enabled');
            allLetterValidation(allLetterInputs);

            // Validation for gas form elements.
            var gasInputs = formElement.find('.form-item.gas input:enabled');
            gasValidation(gasInputs);

            // Validation for electricity form elements.
            var electricityInputs = formElement.find('.form-item.electricity input:enabled');
            electricityValidation(electricityInputs);

            // Validation for gas business form elements.
            var gasBusinessInputs = formElement.find('.form-item.gas-business input:enabled');
            gasBusinessValidation(gasBusinessInputs);

            // Validation for electricity business form elements.
            var electricityBusinessInputs = formElement.find('.form-item.electricity-business input:enabled');
            electricityBusinessValidation(electricityBusinessInputs);

            // Validation for house number form elements.
            var houseNumberInputs = formElement.find('.form-item.house-number input:enabled');
            houseNumberValidation(houseNumberInputs);

            // Validations for name form elements.
            var nameInputs = formElement.find('.form-item.names input:enabled');
            namesValidation(nameInputs);

            // Validations for initials form elements.
            var initialsInputs = formElement.find('.form-item.initials input:enabled');
            initialsValidation(initialsInputs);

            // Validations for postal code form elements.
            var postalCodeInputs = formElement.find('.form-item.postal-code input:enabled');
            postalCodeValidation(postalCodeInputs);

            // Validations for email form elements.
            var emailInputs = formElement.find('.form-item.email input:enabled');
            emailValidation(emailInputs);

            // Validations for phone number form elements.
            var phoneInputs = formElement.find('.form-item.phone input:enabled');
            phoneValidation(phoneInputs);

            // Validations for all-in-one phone number form elements.
            var allInOnePhoneInputs = formElement.find('.form-item.phone-all-in-one input:enabled');
            allInOnePhoneValidation(allInOnePhoneInputs);

            // Validations for all-in-one not mobile phone number form elements.
            var allInOnePhoneInputsNotMobile = formElement.find('.form-item.phone-all-in-one-no-mobile input:enabled');
            allInOneNotMobilePhoneValidation(allInOnePhoneInputsNotMobile);

            // Validations for bank account (IBAN) form elements.
            var ibanInputs = formElement.find('.form-item.iban input:enabled');
            ibanValidation(ibanInputs);

            // Validations for KvK number form elements.
            var kvkInputs = formElement.find('.form-item.kvk input:enabled');
            kvkValidation(kvkInputs);

            // Validations for username form elements.
            var usernameInputs = formElement.find('.form-item.username input:enabled');
            usernameValidation(usernameInputs);

            // Validations for password form elements.
            var passwordInputs = formElement.find('.form-item.password input:enabled');
            passwordValidation(passwordInputs);

            // Validations for monthly amount form elements.
            var monthlyAmountInputs = formElement.find('.form-item.monthly-amount input:enabled');
            monthlyAmountValidation(monthlyAmountInputs);

            // Validations for monthly amount form elements.
            var notZeroInputs = formElement.find('.form-item.not-zero input:enabled');
            notZeroValidation(notZeroInputs);

            // Validations for document input form elements.
            var documentInputs = formElement.find('.form-item.document input:enabled');
            documentValidation(documentInputs);

            // Validations for image input form elements.
            var imageInputs = formElement.find('.form-item.image input:enabled');
            imageValidation(imageInputs);

            // Validations for paired password input form elements.
            var pairedInputs = formElement.find('.form-item[paired] input[type=password]:enabled');
            pairedPasswordValidation(pairedInputs);

            // Validations for paired email input form elements.
            var pairedInputsEmail = formElement.find('.form-item[paired].email input[type=text]:enabled, .form-item[paired].email input[type=email]:enabled');
            pairedEmailValidation(pairedInputsEmail);

            var amountInputs = formElement.find('.form-item.amount input:enabled');
            amountValidation(amountInputs);

            var characterInputs = formElement.find('.form-item.character input:enabled');
            characterValidation(characterInputs);

            // Validations for ICC code
            var iccCodeInputs = formElement.find('.form-item.icc-code input:enabled');
            iccCodeValidation(iccCodeInputs);

            // Validations for NEN initials
            var nenInitialsInputs = formElement.find('.form-item.nen-initials input:enabled');
            nenInitialsValidation(nenInitialsInputs);

            // Validations for NEN prefix
            var nenPrefixInputs = formElement.find('.form-item.nen-prefix input:enabled');
            nenPrefixValidation(nenPrefixInputs);

            // Validations for surname
            var surnameInputs = formElement.find('.form-item.surname input:enabled');
            surnameValidation(surnameInputs);

            var maxCharacterTextareaInputs =  formElement.find('.form-item.max-characters-textarea textarea:enabled');
            maxCharactersTextareaValidation(maxCharacterTextareaInputs);

            // Validation for form elements that are required to be filled in.
            var requiredFormItems = formElement.find('.form-item.required');
            var requiredInputs = requiredFormItems.find(
                'input[type=text]:enabled, ' +
                'input[type=password]:enabled, ' +
                'input[type=email]:enabled, ' +
                'input[type=number]:enabled, ' +
                'input[type=checkbox]:enabled, ' +
                'input[type=radio]:enabled, ' +
                'input[type=file]:enabled, ' +
                'textarea:enabled, ' +
                'select:enabled'
            );
            requiredValidation(requiredInputs);

            // Validates the form and focuses the first invalid input in the form if that exists.
            var formValidation = new LiveValidation(formElement);
            formValidation.validateForm(eventTrigger);

            // End validation in progress.
            formElement.removeClass('validating');
        }
